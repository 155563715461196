.swal-confirm-button {
  background: #444444 !important;
  color: #f8f8f8 !important;
  min-width: 74px !important;
  min-height: 32px !important;
  text-transform: none !important;
  &:focus {
    box-shadow: 0px 0px 2px 3px #999999 !important;
  }
}
