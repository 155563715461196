$ia-gray-light: #f2f2f2;

body {
    background-color: $ia-gray-light;
    margin: 0;
}

.text-icon {
    margin-left: 10px;
}

.container {
  display: flex;
  min-height: 99.2vh;
  flex-direction: column;
  background-color: $ia-gray-light;
  
  .content {
    display: flex;
    flex: 1;
    background-color: $ia-gray-light;

    .left {
      width: 25%;
      text-align: left;
      position: relative;
    
      svg {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    .center {
      flex: 1;
      text-align: center;

      .loginForm {
        margin: 20% 20%;

        .title {
          text-align: left;
          font-size: 22px;
          margin: 10px 0;
        }

        .info {
          text-align: left;
          font-size: 12px;

          .italic {
            font-style: italic;
          }
        }

        svg {
          margin: auto;
        }

        .link {
          text-align: left;
          margin: 15px 0;

          a {
            color: #7FBA00;
            text-decoration: none;
          }
        }

        .errorInfo {
          color: #FF0000;
        }

        .buttons {
          button {
            width: 150px;
            height: 40px;
          }
        }

        .invalidCode {
          .title {
            text-align: center;
            font-size: 22px;
            margin: 10px 0;
          }
  
          .link {
            text-align: center;
            margin: 15px 0;
  
            a {
              color: #7FBA00;
              text-decoration: none;
            }
          }
        }
      }
    }
    
    .right {
      width: 25%;
      text-align: right;
      background-color: white;
      position: relative;
    
      svg {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}