$ia-gray: #999999;
$ia-gray-light: #f8f8f8;
$ia-gray-dark: #444444;

body {
  background-color: $ia-gray-light;
  margin: 0;
}

a {
  color: $ia-gray-dark;
}

.main-div {
  .main-header {
    header {
      background-color: white;

      .main-toolbar {
        margin: auto;
        max-width: 1400px;
        width: 100%;
        padding: 0 20px;
        min-height: auto;
      }

      .main-header-button {
        padding: 5px 20px;
        margin-left: 10px;
        margin-right: 10px;

        &.main-header-button-solid {
          color: white;
          background-color: $ia-gray-dark;
          border-radius: 0;
          margin-left: auto;
        }

        .NavLink {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: inherit;
          font-size: inherit;
          line-height: normal;
          color: #000000;
          background-color: inherit;
          border-width: 0;
          text-decoration: none;
          text-decoration-line: none;

          &.disabled-link {
            pointer-events: none;
          }
        }
      }

      .main-header-text {
        color: black;
        margin-right: 30px;
      }

      .NavLinkSubMenuElement {
        text-align: right;
        width: 200px;
        height: 100%;
        background-color: initial;
        padding: 11px 0 11px 0;
        text-transform: uppercase;
        font-style: normal;
        font-weight: inherit;
        font-size: inherit;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #000000;
        text-decoration-line: none;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .MuiContainer-root {
    max-width: 1400px;
    padding: 0;
  }
}

.MuiDialog-root {
  z-index: 900 !important;
}

.text-icon {
  margin-left: 10px;
}
