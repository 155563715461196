@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../Fonts/Poppins-Regular.ttf) format("truetype");
}

body {
  font-family: Poppins;
}

.img__description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding-top: 30%;
  padding-left: 5%;
  background: rgba(68, 68, 68, 0.72);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  cursor: pointer;
  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}

.img__wrap:hover .img__description {
  visibility: visible;
  opacity: 1;
}
